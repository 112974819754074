import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
//import BackgroundImage from "gatsby-background-image"
//import { getImage } from "gatsby-plugin-image"
//import { convertToBgImage } from "gbimage-bridge"

import "./hero.css"

const BannerPeaksuite = () => {
  //const { heroImage } = useStaticQuery(
  //  graphql`
  //    {
  //      heroImage: file(
  //        relativePath: { eq: "new-images/peaksuite-banner.jpg" }
  //      ) {
  //        childImageSharp {
  //          gatsbyImageData(
  //            width: 1920
  //            layout: CONSTRAINED
  //            formats: [AUTO, WEBP, AVIF]
  //          )
  //        }
  //      }
  //    }
  //  `
  //)

  //const heroImageData = getImage(heroImage)

  //// Use like this:
  //const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      {/*<BackgroundImage
        Tag="div"
        {...bgImage}
        // fluid={imageData}
        className="hero-banner hero-whitefade"
        preserveStackingContext
        style={{ backgroundPosition: "center" }}
      >*/}
      <div
        className="hero-banner hero-whitefade-peaksuite"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-7 mr-auto">
              <div className="hero-procurement">
                <h1 className="page-title font-weight-medium mt-0 mb-4">
                  Manual Tasks Destroy Your Bottom Line. Stay in the Green with
                  Tactical Automation.
                </h1>
                <p>
                  peakSUITE offers a range of turnkey solutions designed to
                  rapidly streamline how work gets done in your organization.
                </p>
                <p>
                  <Link
                    aria-label="link"
                    className="font-weight-medium"
                    to="/contact/"
                  >
                    Speak to a Technology Consultant
                    <i className="ml-2 item-arrow" aria-hidden="true"></i>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      {/*</BackgroundImage>*/}
      </div>
    </>
  )
}

export default BannerPeaksuite
