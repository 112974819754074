import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

// import PeakSuiteBanner from "../../images/new-images/peaksuite-banner.jpg"

import Banner from "../../components/hero/banner-peaksuite.js"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const PeaksuiteSections = loadable(() =>
  pMinDelay(import("../../components/PeaksuiteSections"), 500)
)

class PeakSuitePage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} banner={<Banner />}>
        <Seo
          title="peakSUITE: Custom Add-Ons for Quickbase"
          description="peakSUITE offers cloud-based, modular add-ons built in Quickbase for plug-and-play automation and integration. Get more value out of Quickbase."
        />
        <PeaksuiteSections />
      </Layout>
    )
  }
}

export default PeakSuitePage
